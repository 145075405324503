import React, { useCallback, useEffect, useState } from "react";
import { Tabs } from "../tabs";
import { useDispatch, useSelector } from "react-redux";
import { home } from "../../redux/actions";
import { getQueryParam } from "../../utils";
import { useParams } from "react-router";

const DEFAULT_TABS = {
  "jr.-college": 0,
  ca: 2,
};

const LearningType = ({ tabList }) => {
  const dispatch = useDispatch();
  const paramns = useParams();
  const [data, setData] = useState(tabList);
  const homeState = useSelector((state) => state?.app?.home);

  const setActiveTab = useCallback(
    (tab) => dispatch(home.setActiveLearingType(tab)),
    [dispatch]
  );

  useEffect(() => {
    if (tabList?.length) {
      let defaultIndex = getQueryParam("learning_mode")
        ? tabList.findIndex((tab) => tab.id === getQueryParam("learning_mode"))
        : paramns?.batch
        ? DEFAULT_TABS[paramns?.batch]
        : 2;

      if (defaultIndex === -1) {
        defaultIndex = 2;
      }

      setData(tabList);
      setActiveTab(tabList[defaultIndex]?.id);
    }
  }, [tabList, setActiveTab, paramns?.batch]);

  function name(params) {
    let data = params.replace("(", "<em>(").replace(")", ")</em>");

    return data;
  }

  return (
    <>
      <div className="learningType__wrap">
        <Tabs
          customCls={"tabs__wrap--gradient tabs__wrap--fdcol"}
          active={homeState?.activeLearningType}
          data={data?.map((x) => ({ ...x, title: name(x.title) }))}
          handleClick={(tab) => setActiveTab(tab)}
          type="object"
          displayKey="title"
        />
      </div>
    </>
  );
};

export default LearningType;
